import {Component, Input, OnInit} from '@angular/core';
import {IAngularMyDpOptions} from 'angular-mydatepicker';
import {FormControl, Validators} from '@angular/forms';
import {WorkTrackerService} from '../../modules/work-tracker/_services/work-tracker-service';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-export-csv',
    templateUrl: './export-csv.component.html',
    styleUrls: ['./export-csv.component.scss']
})
export class ExportCsvComponent implements OnInit {

    // Component Inputs
    @Input() exportFor: string;
    @Input() exportForUser: any;
    @Input() startDate: string;
    @Input() endDate: string;

    id: string;
    // Date Picker Options
    defaultDate = new Date();
    myOptions: IAngularMyDpOptions = {
        dateRange: true,
        dateFormat: 'dd-mm-yyyy',
        markCurrentDay: true,
        dateRangeDatesDelimiter: '  --  ',
        calendarAnimation: {in: 1, out: 1},
    };

    // Form Control
    myDateRange = new FormControl({
        isRange: true,
        dateRange: {
            beginJsDate: new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1),
            endJsDate: new Date()
        }
    }, Validators.required);


    constructor(private workTrackerService: WorkTrackerService,
                private datePipe: DatePipe,
                private modalService: NgbModal,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        
        this.route.params.subscribe(params => {
            this.id = params.id;
        });
    }


    getCsvFile() {
        if (this.startDate === undefined && this.endDate === undefined) {
            // tslint:disable-next-line:max-line-length
            this.startDate = this.datePipe.transform(new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), this.defaultDate.getDate() - 30), 'yyyy-MM-dd');
            this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        
        if (this.id) {
            if (this.exportFor === 'userWorkTracker') {
                this.workTrackerService.exportWorkTrackersUser(this.startDate, this.endDate, this.id).subscribe((data: any) => {
                    this.saveFile(`User Work Tracker: ${this.exportForUser} - ${this.startDate} - ${this.endDate}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data);
                });
            } else if (this.exportFor === 'userLeaveRequests') {
                this.workTrackerService.exportUserLeaveRequests(this.startDate, this.endDate, this.id).subscribe((data: any) => {
                    this.saveFile(`Leave Requests: ${this.exportForUser} - ${this.startDate} - ${this.endDate}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data);
                });
            }
        } else {
            if (this.exportFor === 'allLeaveRequests') {
                this.workTrackerService.exportAllUsersLeaveRequests(this.startDate, this.endDate).subscribe((data: any) => {
                    this.saveFile(`All Leave Requests: ${this.startDate} - ${this.endDate}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data);
                });
            } else if (this.exportFor === 'allWorkTracker') {
                
            }
        }

    }

    saveFile(name, type, data) {
        if (data != null && navigator.msSaveBlob) {
            return navigator.msSaveBlob(new Blob([data], {type}), name);
        }
        const a = $('<a style=\'display: none;\'/>');
        const url = window.URL.createObjectURL(new Blob([data], {type}));
        a.attr('href', url);
        a.attr('download', name);
        $('body').append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    openSmall(content) {
        this.modalService.open(content, {centered: true});
    }

}

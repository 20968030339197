import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {toTitleCase} from 'codelyzer/util/utils';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AttachmentsService {


    // Files
    selectedFiles = [];

    constructor(private http: HttpClient) {
    }


    addFiles(moduleName, id, files) {
        return this.http.post('/api/upload' + toTitleCase(moduleName) + 'FileRevised?' + moduleName + 'Id=' + id, files);
    }

    saveFilesChanges(moduleName, id, files) {
        return this.http.put('/api/update' + toTitleCase(moduleName) + 'Files?' + moduleName + 'Id=' + id, files);
    }

    getModuleById(moduleName, id) {
        return this.http.get('/api/get' + toTitleCase(moduleName) + 'ById?' + moduleName + 'Id=' + id);
    }


    // Functionality for create pages
    // Upload Files
    uploadFiles(moduleName, id) {
        return new Observable(observer => {

            // Check if the select have files uploaded
            if (this.selectedFiles.length > 0) {
                // Create new form data
                const formData: FormData = new FormData();

                // Append a file
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    formData.append('files', this.selectedFiles[i]);
                }
                this.addFiles(moduleName, id, formData).subscribe(res => {
                    observer.next(res);
                }, error => {
                    observer.error(new Error(error));
                });
            }
        });
    }

    // Add selected Files
    onSelectFiles(files) {
        this.selectedFiles = files;
    }

    // Clear files
    clearFiles() {
        this.selectedFiles = [];
    }
}
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AttachmentsService} from './_services/attachments.service';
import {Subscription} from 'rxjs';
import {CurrentUserDataService} from '../_services/current-user-data.service';


@Component({
    selector: 'app-add-attachments',
    templateUrl: './add-attachments.component.html',
    styleUrls: ['./add-attachments.component.scss']
})
export class AddAttachmentsComponent implements OnInit, OnDestroy {

    // Component Inputs
    @Input() moduleName;

    // Url ID
    urlId: string;

    // Files
    selectedFiles = [];
    moduleFiles = [];

    // Collapse
    isCollapsed = false;
    isCollapsed2 = true;

    // Changes Made
    filesChanges: boolean;

    // Feedback Messages
    fileError: boolean;
    fileSuccess: boolean;
    feedbackMessage: string;

    // User Roles
    userRollsList = undefined;

    // Unsubscribe
    private unsubscribe: Subscription[] = [];

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private fileService: AttachmentsService,
                public userRollsChecker: CurrentUserDataService) {
    }

    ngOnInit() {
        this.urlId = this.route.snapshot.params.id;

        this.getUser();
        this.loadFiles();
    }


    // Get User Data
    getUser() {
        const getCurrentUser = this.userRollsChecker.getCurrentUserData().subscribe(data => {
            this.userRollsList = data.roles;
        });

        // Add request to unsubscribe list
        this.unsubscribe.push(getCurrentUser);
    }


    loadFiles() {
        if (this.urlId) {
            const moduleData = this.fileService.getModuleById(this.moduleName, this.urlId).subscribe((data: any) => {
                this.moduleFiles = data.attachments;
            });
            this.unsubscribe.push(moduleData);
        } else {
            return;
        }
    }

    onFileChange(event) {
        this.selectedFiles = event.target.files;
        this.fileService.onSelectFiles(event.target.files);
    }


    uploadFile() {
        this.fileService.uploadFiles(this.moduleName, this.urlId).subscribe(data => {
            this.feedbackMsg('success', 'Uploaded successfully!');

            // Change Tab
            this.selectedTab('view-attachments');

            // Reload Files
            this.loadFiles();
        }, error => {
            
            this.feedbackMsg('error', 'Please try again!');
        });
        this.clearFiles();
    }

    saveFilesChanges() {
        const saveChanges = this.fileService.saveFilesChanges(this.moduleName, this.urlId, this.moduleFiles).subscribe(() => {
            this.loadFiles();
            this.filesChanges = false;
            this.feedbackMsg('success', 'Changes saved!');
        }, error => {
            
            this.feedbackMsg('error', 'Error!');
        });

        this.unsubscribe.push(saveChanges);
    }

    clearFiles() {
        this.selectedFiles = [];
        this.fileService.clearFiles();
    }

    cancelFilesChanges() {
        this.loadFiles();
        this.filesChanges = false;
    }


    // Collapsable
    selectedTab(tabName) {
        if (tabName === 'view-attachments') {
            this.isCollapsed = false;
            this.isCollapsed2 = true;

        } else if (tabName === 'import-files') {
            this.isCollapsed = true;
            this.isCollapsed2 = false;
        }
    }


    removeElement(i) {
        this.moduleFiles.splice(i, 1);
        this.filesChanges = true;
    }


    // FeedBack Msg
    feedbackMsg(type, message) {
        if (type === 'success') {
            this.fileSuccess = true;
            this.feedbackMessage = message;

            setTimeout(() => {
                this.fileSuccess = false;
            }, 4000);

        } else if (type === 'error') {
            this.fileError = false;
            this.feedbackMessage = message;

            setTimeout(() => {
                this.fileError = false;
            }, 4000);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
